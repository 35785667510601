import { ERROR_MESSAGE } from "@/consts/errors";
import axios, { Axios, AxiosError } from "axios";

const instance = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (err: AxiosError<{ message: string }>) => {
    console.log("err: ", err);
    try {
      const token = localStorage.getItem("accessToken");

      if (token) {
        if (
          err.response?.status === 403 ||
          err.response?.status === 401 ||
          (err.response?.status === 500 &&
            err.response.data?.message?.includes("JWT expired")) ||
          err.code === "ERR_NETWORK"
        ) {
          localStorage.removeItem("accessToken");
          window.location.reload();
        }
      }
    } catch (e) {
      console.log("Something went wrong: ", e);
    } finally {
      // Throw error here, to handle it upper in promise chain e.g in useEvent hook
      throw err;
    }
  },
);

export const useHttpClient = () => instance;
