import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/utils/tailwind";

const badgeVariants = cva(
  "inline-flex items-center justify-center w-min rounded-full px-2.5 py-1 ct transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-gradient-to-t gradient-cian-stops text-primary-foreground",
        secondary:
          "border-transparent bg-gradient-to-t from-2% to-30% from-[rgba(11,0,190,0.7)] to-[rgba(16,65,210,0.7)] to-78% to-[rgba(21,125,228,0.7)] text-primary-foreground",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);


export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
