import { useEventData } from "@/hooks/use-event-data";
import { CalendarStarIcon } from "@/icons/calendar-star";
import { LikeIcon } from "@/icons/like";
import { LocationIcon } from "@/icons/location";
import { PadlockLockedIcon } from "@/icons/padlock-locked";
import { TelegramIcon } from "@/icons/telegram";
import { TimeIcon } from "@/icons/time";
import { Sport } from "@/types/sport";
import { Divider } from "@/ui-kit/divider";
import { SPORT_ICON_SHADOW_MAP } from "@/utils/icons";
import { Image } from "@/icons/image";
import { useParams } from "react-router-dom";
import { Button } from "@/ui-kit/button";
import { InstagramIcon } from "@/icons/instagram";
import { Skeleton } from "@/ui-kit/skeleton";

export const EventSummary = () => {
  const { id } = useParams();

  const { data, isLoading } = useEventData(id!);

  const SportIcon =
    isLoading || !data?.sport
      ? () => <Skeleton className="mt-[5px] h-8 w-8 rounded-full" />
      : SPORT_ICON_SHADOW_MAP[data?.sport as Sport] || null;

  const location = data?.location ? `${data?.location.latitude}, ${data?.location.longitude}` : "";

  return (
    <div className="flex gap-8 mt-8 flex-col sm:flex-row flex-wrap">
      <div className="flex-[2_1_0] min-w-60 sm:min-w-md max-w-md min-h-60 sm:min-h-md max-h-md">
        {isLoading ? (
          <Skeleton className="h-full w-full" />
        ) : (
          <Image src={data?.avatar} alt="Event Card" className="rounded-xxl" />
        )}
      </div>
      <section className="flex flex-col flex-[3_1_0] bg-green-100 rounded-xxl px-7 py-6 min-w-60 max-w-full">
        <div className="flex items-center">
          <PadlockLockedIcon />
          {isLoading ? (
            <Skeleton className="h-10 w-48 ml-2" />
          ) : (
            <h4 className="h4 ml-2">{data?.name}</h4>
          )}
        </div>
        <div className="ml-[0.2rem] mt-6">
          <div className="flex">
            <CalendarStarIcon />
            {isLoading ? (
              <Skeleton className="h-6 w-56 ml-3" />
            ) : (
              <span className="ct2 ml-2 break-all">{data?.formattedDate}</span>
            )}
          </div>
          <div className="flex mt-3">
            <TimeIcon />
            {isLoading ? (
              <Skeleton className="h-6 w-56 ml-3" />
            ) : (
              <span className="ct2 ml-2 break-all">{data?.time}</span>
            )}
          </div>
          <div className="flex mt-3">
            <LocationIcon className="w-6 h-6" />
            {isLoading ? (
              <Skeleton className="h-6 w-56 ml-3" />
            ) : (
              <span className="ct2 ml-2 break-all">{location}</span>
            )}
          </div>
        </div>
        <section className="flex flex-wrap items-center gap-4 mt-8">
          <Button>Записаться</Button>
          <Button variant="ghost" size="icon">
            <LikeIcon />
          </Button>
        </section>
        <section className="flex flex-wrap justify-start mt-8 sm:mt-auto">
          <div className="flex flex-col text-green-300">
            <span className="h5">Время до мероприятия</span>
            <span className="description">23:20:05</span>
          </div>

          <div className="flex">
            <Divider direction="vertical" lineClassName="border-green-300" />
            <div className="flex flex-col">
              <span className="h5">Вид спорта</span>
              <span>
                {SportIcon && <SportIcon />}
              </span>
            </div>
          </div>
          <div className="flex">
            <Divider direction="vertical" lineClassName="border-green-300" />
            <div className="flex flex-col">
              <span className="h5">Соцсети</span>
              <div className="flex gap-2 mt-[5px]">
                <InstagramIcon />
                <TelegramIcon />
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};
