import { Avatar, AvatarFallback, AvatarImage } from "@/ui-kit/avatar";
import { Card, CardContent, CardHeader, CardTitle } from "@/ui-kit/card";
import { cn } from "@/utils/tailwind";
import { FC } from "react";

type ReviewCardProps = {
  avatarUrl?: string;
  title?: string;
  review?: string;
  color?: string;
};

export const ReviewCard: FC<ReviewCardProps> = ({
  avatarUrl,
  title,
  review,
  color,
}) => {
  return (
    <Card
      className={cn(
        "shadow-button rounded-2xl bg-gradient-to-b from-50%",
        color === "blue" ? "from-blue-400 to-blue-500" : "from-green-200 to-green-300",
      )}
    >
      <CardHeader className="flex-row gap-2">
        <Avatar className="rounded-full text-white">
          <AvatarImage src={avatarUrl} />
          <AvatarFallback>{title?.[0] || 'A'}</AvatarFallback>
        </Avatar>
        <CardTitle className="text-white">
          <span>{title}</span>
        </CardTitle>
      </CardHeader>
      <CardContent className="px-6 text-white">
        <span>{review}</span>
      </CardContent>
    </Card>
  );
};

// background: linear-gradient(180deg, rgba(21, 125, 228, 0.94) 0%, rgba(11, 0, 190, 0.69) 100%);
