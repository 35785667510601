import { useAthleteData } from "@/hooks/use-athlete-data";
import { LikeIcon } from "@/icons/like";
import { TelegramIcon } from "@/icons/telegram";
import { Sport } from "@/types/sport";
import { Divider } from "@/ui-kit/divider";
import { SPORT_ICON_MAP } from "@/utils/icons";
import { Image } from "@/icons/image";
import { useParams } from "react-router-dom";
import { Button } from "@/ui-kit/button";
import { InstagramIcon } from "@/icons/instagram";
import { Skeleton } from "@/ui-kit/skeleton";
import { Rating } from "@/components/rating";
import { ReviewCarousel } from "@/components/carousel/review-carousel";
import { Achievements } from "./achivements";
import { AthleteSummaryAbout } from "./athlete-summary-about";
import { AthleteInviteDialog } from '@/components/dialog';
import dataMock from "@/consts/carousel-mock";

export const AthleteSummary = () => {
  const { id } = useParams();

  const { data, isLoading } = useAthleteData(id!);

  const sportIcons =
    isLoading || !data?.sports
      ? [() => <Skeleton className="mt-[5px] h-8 w-8 rounded-full" />]
      : data?.sports.map((sport) => SPORT_ICON_MAP[sport as Sport] || null);

  const location = data?.location
    ? `${data?.location.latitude}, ${data?.location.longitude}`
    : "";

  return (
    <div className="flex gap-2 mt-8 flex-col sm:flex-row flex-wrap">
      <div className="flex-[1_1_0] flex flex-col min-w-60 sm:min-w-md max-w-md">
        <div className="min-h-80 sm:min-h-md max-h-md">
          {isLoading ? (
            <Skeleton className="h-full w-full" />
          ) : (
            <Image
              src={data?.avatar}
              alt="Event Card"
              className="rounded-xxl w-full h-full object-cover"
            />
          )}
        </div>
        <div className="mt-2.5 flex flex-col items-center">
          <Rating className="mb-2.5" value={data?.rating || 0} />
          <AthleteInviteDialog />
          <Button variant="link" className="text-black bt3">
            Добавить в команду
          </Button>
        </div>
      </div>
      <section className="flex-[4_1_0] min-w-60 max-w-full">
        <div className="flex flex-wrap bg-blue-100 rounded-xxl px-7 py-6 min-h-80 sm:min-h-md max-h-md">
          <div className="flex flex-col flex-[1_1_50%]">
            <div className="flex items-center">
              {isLoading ? (
                <Skeleton className="h-10 w-48" />
              ) : (
                <h4 className="h4">{`${data?.lastName} ${data?.firstName}`}</h4>
              )}
              <Button variant="ghost" size="icon" className="ml-2">
                <LikeIcon />
              </Button>
            </div>
            {isLoading ? (
              <Skeleton className="h-6 w-56" />
            ) : (
              <span className="ct ml-1 break-all">{location}</span>
            )}
            <div className="flex flex-col mt-8">
              <span className="h5">Виды спорта</span>
              <div className="flex gap-2 mt-2">
                {sportIcons.map((Icon, index) => Icon && <Icon key={index} />)}
              </div>
            </div>
            <div className="flex flex-col mt-8">
              <span className="h5">Соцсети</span>
              <div className="flex gap-2 mt-2">
                <InstagramIcon />
                <TelegramIcon />
              </div>
            </div>
          </div>
          <section className="flex flex-[1_1_50%] justify-start">
            <Divider
              direction="vertical"
              lineClassName="border-primary border-l-2"
              className="hidden md:block"
            />
            {isLoading ? (
              <Skeleton className="w-full" />
            ) : (
              <AthleteSummaryAbout
                isCoach={data?.isCoach!}
                description={data?.description}
                tags={data?.tags}
              />
            )}
          </section>
        </div>
        <section className="flex flex-col mt-8">
          <h4 className="h4">Достижения</h4>
          <Achievements
            className="mt-4"
            achievements={data?.achievements || []}
          />
        </section>
        <section className="flex flex-col mt-8  mb-16">
          <h4 className="h4">Отзывы</h4>
          <div className="flex flex-col">
            <ReviewCarousel data={dataMock} color="blue" />
            <Button variant="main" className="mx-auto my-8 h-auto">
              Добавить отзыв
            </Button>
          </div>
        </section>
      </section>
    </div>
  );
};
