import { useEffect, useState } from "react";

import { GetAvatarCategoryEnum } from "@/api/generated";
import { useAvatar } from "@/hooks/api/use-avatar";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { eventsSearchParamsState } from "@/atoms/event/search-params";
import { ListHook } from '@/types/list';
import { useAthlete } from '@/hooks/api/use-athlete';
import { AthleteSummaryCard } from '@/types/athlete';

export const useAthletesList: () => ListHook<AthleteSummaryCard[]> = () => {
  const [data, setData] = useState<AthleteSummaryCard[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  
  const { byCategoryAndIdUrl } = useAvatar();
  const { getAthletes } = useAthlete();

  const searchParams = useRecoilValue(eventsSearchParamsState);
  const resetSearchParams = useResetRecoilState(eventsSearchParamsState);

  const fetchData = async () => {
    setIsLoading(() => true);
    const athletesData = await getAthletes({
      page: page - 1,
      size: pageSize,
      searchParams,
    });

    const promisesList = athletesData?.map(async (athlete) => ({
      id: athlete.id,
      firstName: athlete.firstName,
      lastName: athlete.lastName,
      sports: athlete.sports,
      location: athlete.location,
      avatar: await byCategoryAndIdUrl(GetAvatarCategoryEnum.Account, athlete.id),
    })) || [];

    const athletes: AthleteSummaryCard[] = await Promise.all(promisesList);
    console.log('athletesData: ', athletes);

    setData(athletes);
    setIsLoading(() => false);
  };

  // reset search params on hook unmount
  useEffect(() => {
    return () => {
      resetSearchParams();
    }
  }, [])

  // if search params changed, reset page and fetch data
  useEffect(() => {
    page === 1 ? fetchData() : setPage(1);
  }, [searchParams]);

  useEffect(() => {
    fetchData();
  }, [pageSize, page]);

  return {
    data,
    isLoading,
    page,
    setPage,
    setPageSize,
  };
};
