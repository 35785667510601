import { useEffect, useState } from "react";

import { GetAvatarCategoryEnum } from "@/api/generated";
import { useAvatar } from "@/hooks/api/use-avatar";
import { useEvent } from "@/hooks/api/use-event";
import { EventSummaryCard } from "@/types/event";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { eventsSearchParamsState } from "@/atoms/event/search-params";
import { ListHook } from '@/types/list';

export const useEventList: () => ListHook<EventSummaryCard[]> = () => {
  const [data, setData] = useState<EventSummaryCard[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  
  const { byCategoryAndIdUrl } = useAvatar();
  const { getEvents } = useEvent();

  const searchParams = useRecoilValue(eventsSearchParamsState);
  const resetSearchParams = useResetRecoilState(eventsSearchParamsState);

  const fetchData = async () => {
    setIsLoading(() => true);
    const eventsData = await getEvents({
      page: page - 1,
      size: pageSize,
      searchParams,
    });

    const promisesList = eventsData?.map(async (event) => ({
      name: event.name,
      date: event.date,
      location: `${event.location.latitude}, ${event.location.longitude}`,
      sport: event.sport,
      id: event.id,
      avatar: await byCategoryAndIdUrl(GetAvatarCategoryEnum.Event, event.id),
    })) || [];

    const events: EventSummaryCard[] = await Promise.all(promisesList);

    setData(events);
    setIsLoading(() => false);
  };

  // reset search params on hook unmount
  useEffect(() => {
    return () => {
      resetSearchParams();
    }
  }, [])

  // if search params changed, reset page and fetch data
  useEffect(() => {
    page === 1 ? fetchData() : setPage(1);
  }, [searchParams]);

  useEffect(() => {
    fetchData();
  }, [pageSize, page]);

  return {
    data,
    isLoading,
    page,
    setPage,
    setPageSize,
  };
};
