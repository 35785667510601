import { createBrowserRouter } from "react-router-dom";

import Root from "@/pages/root";
import Athletes from "@/pages/athletes";
import Event from "@/pages/event";
import Events from "@/pages/events";
import EventCreate from "@/pages/event-create";
import EventEdit from "@/pages/event-edit";
import ErrorPage from "@/pages/error-page";
import Login from "@/pages/login";
import Main from "@/pages/main";
import Profile from "@/pages/profile";
import Register from "@/pages/register";
import { ProtectedRoute } from "@/protected-route";
import Athlete from "./pages/athlete";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Main />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/profile",
        element: (
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        ),
      },
      {
        path: "/athletes",
        element: <Athletes />,
      },
      {
        path: "/athletes/:id",
        element: <Athlete />,
      },
      {
        path: "/events",
        element: <Events />,
      },
      {
        path: "/events/create",
        element: (
          <ProtectedRoute>
            <EventCreate />
          </ProtectedRoute>
        ),
      },
      {
        path: "/events/:id",
        element: <Event />,
      },
      {
        path: "/events/:id/edit",
        element: (
          <ProtectedRoute>
            <EventEdit />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);
