import { Card, CardContent, CardHeader, CardTitle } from "@/ui-kit/card";
import { CreateEventForm } from "@/components/forms/create-event";
import { useState } from "react";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/ui-kit/tabs";
import { EventCreatedDialog } from '@/components/dialog';

export const CreateEventCard = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [eventId, setEventId] = useState<number | null>(null);

  const onSubmitHandler = (eventId?: number) => {
    setEventId(eventId || null);
    setOpenDialog(true);
  };

  return (
    <>
      <EventCreatedDialog open={openDialog} onOpenChange={setOpenDialog} eventId={eventId} />
      <Card className="min-w-[300px] w-screen max-w-[500px] m-8 rounded-2xl">
        <CardHeader>
          <CardTitle>Создать событие</CardTitle>
        </CardHeader>
        <CardContent>
          <Tabs defaultValue="new" className="w-[400px]">
            <TabsList>
              <TabsTrigger value="new">Новое</TabsTrigger>
              <TabsTrigger value="external">Существующее</TabsTrigger>
            </TabsList>
            <TabsContent value="new">
              <CreateEventForm onSubmitCallback={onSubmitHandler} />
            </TabsContent>
            <TabsContent value="external">
              <CreateEventForm external onSubmitCallback={onSubmitHandler} />
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
    </>
  );
};
