const dataMock = [
  {
    title: "Иванов Иван Иванович",
    review:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut facere nihil, voluptatem quos voluptatum harum odio incidunt repudiandae sapiente repellendus magnam laudantium deserunt repellat atque hic itaque? Accusamus, quo obcaecati.",
  },
  {
    title: "Иванов Иван Иванович",
    review:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut facere nihil, voluptatem quos voluptatum harum odio incidunt repudiandae sapiente repellendus magnam laudantium deserunt repellat atque hic itaque? Accusamus, quo obcaecati.",
  },
  {
    title: "Иванов Иван Иванович",
    review:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut facere nihil, voluptatem quos voluptatum harum odio incidunt repudiandae sapiente repellendus magnam laudantium deserunt repellat atque hic itaque? Accusamus, quo obcaecati.",
  },
];

export default dataMock;
