import { Button } from "@/ui-kit/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/ui-kit/dialog";
import { Label } from "@/ui-kit/label";
import { Switch } from "@/ui-kit/switch";
import { Textarea } from "@/ui-kit/text-area";

export const AthleteInviteDialog = () => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button>Написать</Button>
      </DialogTrigger>
      <DialogContent className="px-14 pt-20 pb-10 max-w-[32rem]">
        <DialogHeader>
          <DialogTitle className="h5 text-center">
            Отправить спортсмену приглашение на вступление в вашу команду?
          </DialogTitle>
          <DialogClose />
        </DialogHeader>
        {/* TODO For now it's ok, but when there will be BE need to extract to form component */}
        <Textarea placeholder="Сообщение" />
        <div className="flex items-center space-x-2">
          <Switch title="Анонимно" id="anonymous" />
          <Label htmlFor="anonymous">анонимно</Label>
        </div>
        <DialogFooter className="flex gap-2 md:justify-center">
          <DialogClose asChild>
            <Button>Отправить</Button>
          </DialogClose>
          <DialogClose asChild>
            <Button variant="outlineMain">Отмена</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
