import { useMemo } from "react";
import { Pagination } from "@/components/pagination";
import { Skeleton } from "@/ui-kit/skeleton";
import { useAthletesList } from "@/hooks/list/use-athletes-list";
import { AthleteCard } from "@/components/card/athlete-card";

export const AthletesList = () => {
  const { data, isLoading, page, setPage, setPageSize } = useAthletesList();

  const athletes = useMemo(
    () =>
      isLoading
        ? Array.from({ length: 8 }).map((_, index) => (
            <Skeleton
              key={index}
              className="min-w-48 w-screen max-w-[282px] min-h-96 h-screen max-h-[500px] rounded-2xl"
            />
          ))
        : data.map((athlete, index) => (
            <AthleteCard
              key={index}
              {...athlete}
              location={`${athlete.location.latitude} ${athlete.location.longitude}`}
              buttonText="Подробнее"
            />
          )),
    [data, isLoading],
  );

  return (
    <div className="content-padding-64 md:content-padding-56 mt-12">
      <div className="flex justify-between">
        <div className="flex gap-4 items-end">
          <h4 className="h4">Спортсмены</h4>
          <span className="ct text-gray-100 mb-[0.3rem]">
            Найдено: {data.length}
          </span>
        </div>
        <div>Фильтр</div>
      </div>
      <>
        <div className="flex flex-wrap gap-2 justify-center md:justify-start my-8 min-h-96">
          {athletes}
        </div>
        <Pagination total={10} onChange={setPage} defaultCurrent={page} />
      </>
    </div>
  );
};
