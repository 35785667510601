import { AthleteSummary } from "@/components/athlete-summary";

const Athlete = () => {
  return (
    <div className="bg-white w-full content-padding-64">
      <AthleteSummary />
    </div>
  );
};

export default Athlete;
