import { BronzeAchivementIcon } from '@/icons/achivements/bronze';
import { GoldAchivementIcon } from '@/icons/achivements/gold';
import { RegularAchivementIcon } from '@/icons/achivements/regular';
import { SilverAchivementIcon } from '@/icons/achivements/silver';
import { RunningIcon } from '@/icons/sports/running';
import { RunningShadowIcon } from '@/icons/sports/running-shadow';
import { SkiingIcon } from '@/icons/sports/skiing';
import { SkiingShadowIcon } from '@/icons/sports/skiing-shadow';
import { SwimmingIcon } from '@/icons/sports/swimming';
import { SwimmingShadowIcon } from '@/icons/sports/swimming-shadow';

export const SPORT_ICON_SHADOW_MAP = {
    Running: RunningShadowIcon,
    Swimming: SwimmingShadowIcon,
    Skiing: SkiingShadowIcon,
    Bicycle: null
}

export const SPORT_ICON_MAP = {
    Running: RunningIcon,
    Swimming: SwimmingIcon,
    Skiing: SkiingIcon,
    Bicycle: null,
}

export const ACHIEVEMENTS_ICON_MAP = {
    Regular: RegularAchivementIcon,
    Bronze: BronzeAchivementIcon,
    Silver: SilverAchivementIcon,
    Gold: GoldAchivementIcon
}