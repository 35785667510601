import { SearchWithFiltersCard } from "@/components/card/search-with-filters-card";
import { AthletesList } from '@/components/list/athletes-list';

const Athletes = () => {
  return (
    <div className="bg-white w-full">
      <div className="content-padding-64 mt-12">
        <SearchWithFiltersCard />
      </div>
      <AthletesList />
    </div>
  );
};

export default Athletes;
