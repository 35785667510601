import { FC } from "react";

import { Card } from "@/ui-kit/card";
import { Button } from "@/ui-kit/button";
import { Image } from "@/icons/image";
import { LikeIcon } from "@/icons/like";
import { CalendarIcon } from "@/icons/calendar";
import { LocationIcon } from "@/icons/location";

import { format } from "date-fns";
import { Link } from "react-router-dom";
import { Sport } from "@/types/sport";
import { SPORT_ICON_SHADOW_MAP } from "@/utils/icons";
import { cn } from "@/utils/tailwind";

type EventCardProps = {
  id: number;
  avatar: string;
  name: string;
  date: string;
  location: string;
  sport: string;
  buttonText: string;
  className?: string;
  onClickHandler?: () => void;
  onLikeHandler?: () => void;
};

export const EventCard: FC<EventCardProps> = ({
  id,
  avatar,
  name,
  date,
  location,
  sport,
  buttonText,
  className,
  onLikeHandler,
}) => {
  const SportIcon = SPORT_ICON_SHADOW_MAP[sport as Sport] || null;

  return (
    <Card
      className={cn(
        "min-w-48 w-screen max-w-[282px] min-h-96 h-screen max-h-[500px] rounded-2xl overflow-hidden group",
        className,
      )}
    >
      <div className="relative flex-1">
        <Image src={avatar} alt="Event Card" />
        {SportIcon && <SportIcon className="absolute top-2 right-2" />}
      </div>
      <section className="z-10 flex flex-col items-center flex-1 p-4 -mt-20 transition-colors bg-gradient-to-b from-transparent from-2% to-gray-75 to-15% group-hover:bg-gradient-to-t group-hover:gradient-cian-stops">
        <h6 className="h6 mt-12">{name}</h6>
        <p className="ft1 flex mt-3">
          <CalendarIcon />
          <span className="ml-1">
            {date ? format(date, "dd.MM.yyyy HH:mm") : "Неизвестно"}
          </span>
        </p>
        <p className="ft1 flex mt-2">
          <LocationIcon />
          <span className="ml-1">{location}</span>
        </p>
        <div className="mt-auto gap-2 flex items-center justify-between flex-wrap-reverse">
          <Button className="mt-4" asChild>
            <Link to={`/events/${id}`}>{buttonText}</Link>
          </Button>
          <Button
            className="mt-4 hover:bg-transparent"
            variant="ghost"
            size="icon"
            onClick={onLikeHandler}
          >
            <LikeIcon className='hover:fill-black transition-colors' />
          </Button>
        </div>
      </section>
    </Card>
  );
};
