import { z } from "zod";
import { cutBase64Header, notEmpty } from "@/schemas/utils";
import isISODate from "is-iso-date";
import { ERROR_MESSAGE } from "@/consts/errors";

const eventSchema = z.object({
  id: z.number(),
  name: z.string().pipe(notEmpty),
  sport: z.string(),
  location: z.object({
    latitude: z.number(),
    longitude: z.number(),
  }),
  date: z
    .string()
    .refine(isISODate, { message: ERROR_MESSAGE.invalidDate })
    .default(""),
  description: z.string().optional(),
  avatar: z.string().transform(cutBase64Header).optional(),
  isPublic: z.boolean(),
  distance: z.number().optional(),
  socialMedia: z.array(z.string()).optional(),
  format: z.string().optional(),
  training: z.string().optional(),
});

const externalEventSchema = eventSchema.extend({
  externalEventUrl: z.string(),
  externalRegistrationUrl: z.string(),
});

const eventSearchSchema = z.object({
  name: z.string().optional(),
  location: z
    .object({
      latitude: z.number(),
      longitude: z.number(),
    })
    .optional(),
  sportId: z.number().optional(),
  date: z
    .string()
    .refine(isISODate, { message: ERROR_MESSAGE.invalidDate })
    .optional(),
  distance: z.coerce
    .number()
    .refine((val) => val >= 0, { message: ERROR_MESSAGE.invalidDistance })
    .optional(),
});

export { eventSchema, externalEventSchema, eventSearchSchema };
