import { useState, useEffect } from "react";
import { GetAvatarCategoryEnum } from "@/api/generated";
import { useAvatar } from "@/hooks/api/use-avatar";
import { useAthlete } from '@/hooks/api/use-athlete';
import { AthleteDetailCard } from '@/types/athlete';


export const useAthleteData = (id: string) => {
  const [data, setData] = useState<AthleteDetailCard>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { getAthlete } = useAthlete();
  const { byCategoryAndIdUrl } = useAvatar();

  const fetchData = async () => {
      setIsLoading(() => true);
      const idNum = Number(id);
      const data = await getAthlete(idNum);
      const avatarUrl = await byCategoryAndIdUrl(
        GetAvatarCategoryEnum.Account,
        idNum,
      );
      if (data) {
        setData({
          ...data,
          avatar: avatarUrl,
          location: data.location,
          rating: 4,
          achievements: ['Bronze', 'Silver', 'Gold', 'Regular'],
        });
      }
      setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return {
    data,
    isLoading,
  };
};
