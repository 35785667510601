import { StarIcon } from "@/icons/star";
import { cn } from "@/utils/tailwind";
import { FC } from "react";

type RatingProps = {
  value: number;
  className?: string;
};

export const Rating: FC<RatingProps> = ({ value, className }) => {
  return (
    <div className={cn("flex gap-1.5", className)}>
      {Array.from({ length: 5 }).map((_, index) => (
        <StarIcon
          key={index}
          className={cn(
            "text-orange-300 transition-colors hover:fill-orange-300 cursor-pointer",
            index < value ? "fill-orange-300" : "",
          )}
        />
      ))}
    </div>
  );
};
