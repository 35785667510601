import { z } from "zod";
import { cutBase64Header } from "@/schemas/utils";

const athleteSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  location: z
    .object({
      latitude: z.number(),
      longitude: z.number(),
    })
    .optional(),
  sportIds: z.array(z.number()),
  description: z.string(),
  avatar: z.string().transform(cutBase64Header).optional(),
});

const athleteSearchSchema = z.object({
  name: z.string().optional(),
  location: z
    .object({
      latitude: z.number(),
      longitude: z.number(),
    })
    .optional(),
  sportId: z.number().optional(),
});

export { athleteSchema, athleteSearchSchema };
